import React from 'react';
import PropTypes from 'prop-types';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Ymir from '../src/components/Ymir/Ymir';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import { Odin } from '../src/components/Odin/Odin';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';

import './nosotros.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query nosotros($locale: String!) {
    allNosotrosSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allNosotrosImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          image
          image_alt
        }
      }
    }
    allNosotrosTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allNosotrosImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          adress
          cta
          ctaText
          description
        }
      }
    }
    allNosotrosMulticolumnBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allNosotrosBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allNosotrosRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class nosotros extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allNosotrosTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allNosotrosTitleBlock.edges[0].node.description,
      image: {
        url: this.props.data.allNosotrosImageHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allNosotrosImageHeaderBlock.edges[0].node.alt,
      },
    };

    const h3Data = {
      title: this.props.data.allNosotrosMulticolumnBlock.edges[0].node.title,
    };

    const odinData = {
      image: this.props.data.allNosotrosImageBlock.edges[0].node.image,
      alt: this.props.data.allNosotrosImageBlock.edges[0].node.image_alt,
      title: this.props.data.allNosotrosImageHeaderBlock.edges[0].node.adress,
      description: `${this.props.data.allNosotrosImageHeaderBlock.edges[0].node.description}<a href="${this.props.data.allNosotrosImageHeaderBlock.edges[0].node.cta}">${this.props.data.allNosotrosImageHeaderBlock.edges[0].node.ctaText}</a>`,
      titleType: 'h3',
      buttons: {
        cta1: tt('/entradas', this.props.pageContext.locale),
        cta2: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        ctaText1: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
        ctaText2: tt('RESERVAR', this.props.pageContext.locale),
        size: 'small',
        color1: 'white',
        color2: 'orange',
      },
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allNosotrosSeoBlock.edges[0].node._0.title}
          description={this.props.data.allNosotrosSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allNosotrosRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allNosotrosImageHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="nosotros-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allNosotrosBreadCrumbBlock.edges[0].node.name}
              />
              <H3 data={h3Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div
                className="text-container"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allNosotrosMulticolumnBlock.edges[0].node.description,
                }}
              />
              <Ymir data={ymirData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default nosotros;
